import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, GithubIcon, MediumIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            /* {
        label: "Contact",
        href: "https://docs.pancakeswap.finance/contact-us",
      }, */ {
                label: "Blog",
                href: "https://pancakeswap.medium.com/"
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram"
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake"
            },
            {
                label: "\u2014"
            }
        ]
    },
    {
        label: "Help",
        items: [
            /*  {
        label: "Customer",
        href: "Support https://docs.pancakeswap.finance/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.pancakeswap.finance/help/troubleshooting",
      }, */ {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap"
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            },
            /* {
        label: "Bug Bounty",
        href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
      }, */ {
                label: "Audits",
                href: ""
            }
        ]
    }, 
];
export var socials = [
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/cloudaxHQ"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        items: [
            {
                label: "English Channel",
                href: "https://t.me/cloudaxOfficial"
            }
        ]
    },
    /*  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com/r/pancakeswap",
  },
  {
    label: "Instagram",
    icon: InstagramIcon,
    href: "https://instagram.com/pancakeswap_official",
  }, */ {
        label: "Github",
        icon: GithubIcon,
        href: "https://github.com/Blockchain-lounge"
    },
    {
        label: "Medium",
        icon: MediumIcon,
        href: "https://cloudax.medium.com"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
